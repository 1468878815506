<script>
export default {
  name: "UserDescEdit",

  props: {
    userId: {
      type: [String, Number],
      required: true
    },
  },

  data() { return {
    inputValue: "",

    showSaveBtn: false
  }},

  mounted() {
    this.FetchDescription();
  },

  methods: {
    FetchDescription()
    {
      this.$api.get({ path: `users/${this.userId}/description` }, this.OnFetchResponse)
    },

    OnFetchResponse({response})
    {
      const { user_desc } = response.data;
      this.$refs.richText?.SetContent(user_desc);
    },

    SaveDescription() 
    {
      const data = {
        user_description: this.inputValue
      };

      this.$api.patch({ path: `users/${this.userId}/description` , data }, this.OnSaveResponse);
    },

    OnSaveResponse()
    {
      this.showSaveBtn = false;
    }
  }
}
</script>

<template>

  <rich-text-area placeholder="Fisa postului" class="user-desc-edit" maxlength="5000" 
    @input="showSaveBtn = true" 
    v-model:value="inputValue"
    :disabled="!$session.HasAccess(['manage_users'])"
    ref="richText"
  >

    <button class="save-btn" type="button" @click="SaveDescription" v-if="showSaveBtn">
      {{$lan("btn_save")}}
    </button>

  </rich-text-area>

</template>


<style scoped>
.user-desc-edit {
  display: flex;
  max-width: 425px;
  /* word-wrap: break-word; */
  /* flex-grow: 2; */
  height: 150px;
  padding: 10px;
  margin-bottom: 20px;
  justify-content: flex-end;
  background-color: var(--dark-box-color);
  border-radius: 20px;
  word-break: break-all;
}

.save-btn {
  width: fit-content;
  align-self: center;
  animation: slide-up 0.3s;
}
</style>