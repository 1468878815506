<script>
import AvatarEdit from '../../../components/AvatarEdit.vue';
import UserDescEdit from '../../../components/UserDescEdit.vue';


export default {
  name: 'edit_profile',

  components: { AvatarEdit, UserDescEdit },
  emits: ['update'],

  props: {
    'userData': Object
  },

  data() { return {
    waitResponse: false,
    isDataMutated: false,

    newData : {
      s_name: "",
      f_name: "",
      phone: "",
      email: "",
    },
  }},


  methods: {
    SaveData()
    {
      this.waitResponse = true;

      const data = { 
        user_data: this.GetRequestData() 
      };

      this.$api.patch({ path: `users/${this.userData.id}`, data }, this.OnSaveDataResponse);
    },

    GetRequestData()
    {
      let requestData = {};

      for(const [key, value] of Object.entries(this.newData)) {
        if(!value) {
          continue;
        }

        requestData[key] = value;
      }

      return requestData;
    },

    OnSaveDataResponse({response})
    {
      const { user_id, user_data } = response.data;

      this.$msg.tpush("profile_saved");

      if(this.$session.IsUser(user_id)) {
        this.$session.UpdateUserData(user_data);
      }

      this.EmitNewData(user_data);

      this.newData = {};
      this.waitResponse = false;
      this.isDataMutated = false;
    },

    EmitNewData(userData)
    {
      this.$emit('update', userData);
    },

    OnFieldInput()
    {
      this.isDataMutated = true;
    },
  }
}
</script>


<template>
  <form @submit.prevent="SaveData" class="vertical-container">

    <avatar-edit :user-id="userData.id" :url="userData.picture"></avatar-edit>

    <user-desc-edit :user-id="userData.id"></user-desc-edit>

    <div class="input-row">
      <label>Prenume</label>

      <input-box @input="OnFieldInput"
        v-model:value="newData.f_name" 
        pattern="[A-z]+"
        maxlength="50" 
        :copy-content="userData.f_name" 
        :placeholder="userData.f_name" 
      />
    </div>

    <div class="input-row">
      <label>Nume</label>

      <input-box @input="OnFieldInput"
        v-model:value="newData.s_name" 
        pattern="[A-z]+"
        maxlength="50" 
        :copy-content="userData.s_name" 
        :placeholder="userData.s_name" 
      />
    </div>

    <div class="input-row">
      <label>Telefon</label>

      <input-box @input="OnFieldInput"
        v-model:value="newData.phone" 
        type="tel"
        pattern="(\+?( |-|\.)?\d{1,2}( |-|\.)?)?(\(?\d{3}\)?|\d{3})( |-|\.)?(\d{3}( |-|\.)?\d{4})"
        maxlength="12" 
        :copy-content="userData.phone" 
        :placeholder="userData.phone" 
      />
    </div>

    <div class="input-row">
      <label>E-mail</label>

      <input-box @input="OnFieldInput"
        v-model:value="newData.email" 
        maxlength="50" 
        :copy-content="userData.email" 
        :placeholder="userData.email" 
        type="email"
      />
    </div>

    <div class="input-row" style="margin-top: 20px;">
      <l-button :active="waitResponse" :disabled="!isDataMutated" type="submit" class="blue border-btn">
        {{$lan('btn_save')}}
      </l-button>
    </div>

  </form>
</template>

<style scoped>
.copy-button {
  position: absolute;
  display: none;
  width: 35px;
  height: 35px;
  padding: 0;
  box-shadow: none;
}

.p-row:hover .copy-button {
  display: inline-block;
}

</style>

