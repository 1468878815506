<script>
export default {
  name: "AvatarEdit",

  emits: ['updateAvatar'],

  props: {
    userId: {
      type: [String, Number],
      required: true
    },

    url: {
      type: String,
      default: ""
    }
  },

  data() { return {
    inputValue: ""
  }},

  computed: {
    placeHolder() {
      return this.url || ".jpg .png direct link";
    },

    copyContent() {
      return this.inputValue ? null: this.url;
    }
  },

  methods: {
    SaveAvatar() 
    {
      if(!this.inputValue) {
        return;
      }

      const params = {
        'avatar-url': this.inputValue
      };

      this.$api.post({ path: `users/${this.userId}:update-avatar` , params}, this.OnSaveResponse)
    },

    OnSaveResponse({response})
    {
      if(response.status != 'success') {
        return;
      }

      // const { avatar_url } = response.data;

      // this.$emit('updateAvatar', avatar_url);
      this.inputValue = "";
      window.location.reload();
    }
  }
}
</script>

<template>
  <form class="avatar-edit" @submit.prevent="SaveAvatar">
    
    <label>
      Avatar
    </label>
    
    <div class="content">
      <input-box maxlength="150" pattern="^https?://.+(\.png|\.jpg|\.gif)$" :copy-content="copyContent" v-model:value="inputValue" :placeholder="placeHolder">
        <icon-button type="submit" class="no-bg save-avatar-btn" v-if="inputValue">
          upload
        </icon-button>
      </input-box>

      <div class="field-desc">
        <li>Introduceti adresa directa URL pentru imagine .png sau .jpg</li>
        <li>URL-ul poate avea o lungime maxima 150 de caractere</li>
      </div>
    </div>

  </form>
</template>


<style scoped>
.avatar-edit {
  display: flex;
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  justify-content: flex-end;
  background-color: var(--dark-box-color);
  border-radius: 20px;
}

.avatar-edit .content {
  margin-left: 40px;
  width: 300px;
}

.avatar-edit .input-box {
  width: 100%;
}

.avatar-edit label {
  font-weight: bold;
  margin: 10px;
}

.field-desc {
  font-size: small;
  padding: 10px;
  width: 100%;
}

.field-desc > li {
  padding-bottom: 10px;
}

.save-avatar-btn {
  margin-right: 5px;
  font-size: large;
}

@media only screen and (max-width: 1000px) {
  .avatar-edit {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
  }

  .avatar-edit .content {
    width: 100%;
    margin: 0;
  }

}
</style>